<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Activate CPP Registration
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="referenceNo"
            label="Reference Number"
            dense
            class="black--text mb-4"
            type="text"
            maxLength="20"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="success"
            text
            @click="submit"
          >
            Submit
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPackage } from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {
  components: {
    snackbar,
  },

  props: {
    showDialog: Boolean,
    data: null,
    errorMessage: [],
  },

  setup(props, { emit }) {
    const icon = { entry: mdiPackage }
    const dialog = ref(props.showDialog)
    const loading = ref(false)
    const referenceNo = ref(null)
    const { store, responseMessageStatus, responseMessage } = crud()

    watch(() => props.showDialog, val => {
      dialog.value = val
    })

    const close = () => {
      dialog.value = false
      emit('closeDialog')
    }

    const submit = async () => {
      await store(`${process.env.VUE_APP_URI}/api/admin/members/activate-cpp`, loading, { 
        memberId: props.data,
        transactionNumber: referenceNo.value,
      })

      if (responseMessageStatus.value === 'success') {
        emit('updateTable')
        setTimeout(function() {
          emit('closeDialog')
        }, 2000)
      }
    }

    return {
      dialog,
      icon,
      submit,
      close,
      responseMessage,
      responseMessageStatus,
      loading,
      referenceNo,
    }
  },
}
</script>
