<template>
  <div class="user-tab-overview">
    <!-- activity timeline -->
    <v-card class="elevation-1">
      <v-card-title>
        Activity logs
        <v-row>
          <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mb-2"
              @keypress.enter="searchTable=search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="searchTable"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        dense
      >
        <template #[`item.gross`]="{ item }">
          <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.gross) }}</span>
        </template>
        <template #[`item.wtax`]="{ item }">
          <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.wtax) }}</span>
        </template>
        <template #[`item.net`]="{ item }">
          <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.net) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { ref, watch } from '@vue/composition-api'
  import getData from '@/composables/getData'
  import snackbar from '@/components/SnackBar.vue'

  export default {
    setup() {
      const search = ref('')
      const headers = ref([
        { text: 'Particular', align: 'start', value: 'task' },
        { text: 'Date', align: 'start', value: 'created_at' },
      ])
      const items = ref([])
      const totalItems = ref(0)
      const searchTable = ref('')
      const options = ref({})
      const loading = ref(false)

      const listUri = `${process.env.VUE_APP_URI}/api/transaction-history`

      watch(options, () => {
        getData(listUri, items, totalItems, loading, options, search)
      })

      watch(searchTable, () => {
        options.value.page = 1
        getData(listUri, items, totalItems, loading, options, search)
      })

      return {
        search,
        headers,
        items,
        searchTable,
        options,
        totalItems,
        loading,
      }
    },

    components: {
      snackbar,
    },
  }
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
