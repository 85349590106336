<template>
  <v-row>
    <v-col
      cols="12"
      lg="9"
      md="9"
      xl="9"
    >
      <cart
        @data="getData"
        :customerType="customerType"
        :basket="basket"
        :uniqueId="uniqueId"></cart>
    </v-col>
    <v-col
      cols="12"
      class="mb-6"
      lg="3"
      md="3"
      xl="3"
    >
      <form-view
        :transaction="transaction"
        :cart="data"
        :uniqueId="uniqueId"
        @customerTypeChange="customerTypeChange"
        @clearTable="clear"></form-view>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from '@/views/Cashiering/form.vue'
import Cart from '@/views/Cashiering/cart.vue'
import get from '@/composables/get'

export default {
  metaInfo: {
    title: 'Cashiering',
  },

  components: {
    Cart,
    FormView,
  },

  setup(props, { root }) {
    const formData = ref({})
    const uniqueId = ref(null)
    const transaction = ref(null)
    const error = ref(null)
    const data = ref(null)
    const customerType = ref('WALKIN')
    const basket = ref([])

    const getData = val => {
      data.value = val.value
      uniqueId.value = Math.random()
    }

    const customerTypeChange = val => {
      customerType.value = val
    }

    const clear = () => {
      basket.value = []
    }

    const getDataOnload = async () => {
      error.value = null
      if (root.$route.params.id !== undefined) {
        await get(`${process.env.VUE_APP_URI}/api/cart/${root.$route.params.id}/transaction`, transaction, null, error)
        if(error.value === null) {
          basket.value = transaction.value.sale_detail
          data.value = transaction.value.sale_detail
          uniqueId.value = Math.random()
        }
      }
    }

    getDataOnload()

    return {
      uniqueId,
      formData,
      getData,
      data,
      customerTypeChange,
      customerType,
      clear,
      basket,
      transaction,
    }
  },
}
</script>
