import axios from 'axios'

const blob = async (uri, storageVariable, _loading, _error = undefined) => {
  const error = _error
  const items = storageVariable
  const loading = _loading

  try {
    if (loading !== null) {
      loading.value = true
    }
    const response = await axios.get(`${uri}`, { responseType: 'blob' })
    items.value = response.data
    if (loading !== null) {
      loading.value = false
    }
  } catch (err) {
    if (err.response !== undefined) {
      if (error !== undefined) error.value = err.response
    }
    if (loading !== null) {
      loading.value = false
    }
  }
}

export default blob
