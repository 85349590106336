<template>
  <v-card class="elevation-1">
    <v-card-title>
      CPP Genealogy
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.registration_code`]="{ item }">
        <v-chip
          class="ma-2 clickable"
          color="success"
          small
          label
          style="cursor: pointer;"
          :to="{name: 'cpp-structure', params: {downlineId: item.id}}"
        >
          {{item.registration_code}}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import {
  mdiCalendarOutline,
  mdiFilter,
  mdiCancel,
  mdiFileExcel,
} from '@mdi/js'
import { useRouter } from '../../@core/utils'

export default {
  setup(props) {
    const { route } = useRouter()
    const search = ref('')
    const headers = ref([
      { text: 'Date Activated', align: 'start', value: 'activated_at' },
      { text: 'Member ID', align: 'start', value: 'registration_code' },
      { text: 'Member Name', align: 'start', value: 'name' },
      { text: 'Sponsor', align: 'start', value: 'sponsor' },
      { text: 'placement', align: 'start', value: 'placement' },
      { text: 'Group', align: 'start', value: 'position' },
      { text: 'Level', align: 'middle', value: 'level' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)

    const listUri = `${process.env.VUE_APP_URI}/api/admin/genealogy/cpp-binary-detailed`

    watch(options, () => {
        getData(listUri, items, totalItems, loading, options, searchTable)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, searchTable)
    })

    watch(() => route.value.params.downlineId, val => {
      if(val != undefined) { 
        options.value.page = 1
        getData(`${listUri}/${val}` , items, totalItems, loading, options, searchTable)
      } else {
        options.value.page = 1
        getData(listUri, items, totalItems, loading, options, searchTable)
      }
    })

    return {
      icon: {
        calendar: mdiCalendarOutline,
        filter: mdiFilter,
        reset: mdiCancel,
        excel: mdiFileExcel,
      },

      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      exportLoading,
    }
  },

  components: {
    snackbar,
  },
}
</script>
