<template>
  <v-card class="elevation-1">
    <v-card-title>
      Sales
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" lg="4">
          <v-btn color="success" :to="{name: 'cart'}">Add New</v-btn>
        </v-col>
        <v-col cols="12" md="4" lg="4" offset-md="4" offset-lg="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.amount`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.amount) }}</span>
      </template>
      <template #[`item.action`]="{ item }">
        <div v-if="item.created_at.includes(dateToday)">
          <v-btn
            x-small
            icon
            @click="confirmOverrideItem(item)"
            title="Override Transaction"
          >
            <v-icon>{{ icon.edit }}</v-icon>
          </v-btn>
          <v-btn
            x-small
            icon
            title="Void Transaction"
            @click="confirmVoidItem(item)"
          >
            <v-icon>{{ icon.void }}</v-icon>
          </v-btn>
          <v-btn
            x-small
            icon
            title="Print Transaction"
            @click="confirmPrint(item)"
          >
            <v-icon>{{ icon.print }}</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmVoid"
      @cancel="showConfirmVoid=false"
      @execute="voidItem">
    </confirm-password>

    <confirm-password
      :show="showConfirmOverride"
      @cancel="showConfirmOverride=false"
      @execute="overrideItem">
    </confirm-password>

    <confirm-password
      :show="showConfirmPrint"
      @cancel="showConfirmPrint=false"
      @execute="print">
    </confirm-password>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import { mdiFileCancelOutline, mdiPencilOutline, mdiPrinter, mdiPrinterPos } from '@mdi/js'
import crud from '@/composables/crud'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import { useRouter } from '@/@core/utils'
import blob from '@/composables/blob'
import printJS from 'print-js'

export default {
  components: {
    snackbar,
    ConfirmPassword,
  },

  setup(props) {
    const { router } = useRouter()
    const search = ref('')
    const headers = ref([
      { text: 'Reference Number', align: 'start', value: 'reference_number' },
      { text: 'Sold To', align: 'start', value: 'sold_to' },
      { text: 'Buyer Type', align: 'start', value: 'buyer_type' },
      { text: 'Date Purchased', align: 'end', value: 'created_at' },
      { text: 'Amount', align: 'end', value: 'amount' },
      { text: 'Action', align: 'center', value: 'action' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const icon = {
      void: mdiFileCancelOutline,
      edit: mdiPencilOutline,
      print: mdiPrinter,
    }
    const showConfirmOverride = ref(false)
    const showConfirmVoid = ref(false)
    const showConfirmPrint = ref(false)
    const selectedItem = ref(null)

    const error = ref(null)
    const receiptData = ref(null)

    const {
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const d = new Date()
    const arrD = d.toISOString().substring(0, 10).split("-")
    const dateToday = `${arrD[1]}/${arrD[2]}/${arrD[0]}`

    const listUri = `${process.env.VUE_APP_URI}/api/cart/masterlist`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    const confirmOverrideItem = (item) => {
      selectedItem.value = item
      showConfirmOverride.value = true
    }

    const overrideItem = () => {
      router.push({ name: 'cart', params: { 'id': selectedItem.value.id}})
    }

    const confirmVoidItem = async (item) => {
      showConfirmVoid.value = true
      selectedItem.value = item
    }

    const confirmPrint = async (item) => {
      showConfirmPrint.value = true
      selectedItem.value = item
    }

    const voidItem = async () => {
      await update(`${process.env.VUE_APP_URI}/api/cart/${selectedItem.value.id}/void`, undefined, {}, undefined)
    }

    const print = async () => {
      await blob(`${process.env.VUE_APP_URI}/api/cart/${selectedItem.value.reference_number}/receipt`, receiptData, loading, error)
      if (error.value === null) {
        console.log(receiptData.value)
        const url = window.URL.createObjectURL(new Blob([receiptData.value], { type: 'application/pdf' }))
        printJS(url)
        // window.open(url)
      }
    }

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      icon,

      showConfirmVoid,
      showConfirmOverride,
      showConfirmPrint,

      print,
      overrideItem,
      voidItem,
      confirmVoidItem,
      confirmOverrideItem,
      confirmPrint,
      responseMessage,
      responseMessageStatus,
      dateToday,
    }
  },
}
</script>
