import { mdiAccount, mdiCart, mdiCreditCard, mdiHomeOutline, mdiWallet } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  // {
  //   title: 'Cashiering',
  //   icon: mdiCart,
  //   to: 'cashiering',
  // },
  {
    title: 'Members',
    icon: mdiAccount,
    to: 'members',
  },
  {
    title: 'Stockist',
    icon: mdiAccount,
    to: 'stockist',
  },
  {
    subheader: 'GENEALOGY',
  },
  {
    title: 'CPP',
    icon: mdiAccount,
    to: 'cpp-structure',
  },
  {
    subheader: 'PAYOUTS',
  },
  {
    title: 'Referral Bonus',
    icon: mdiCreditCard,
    to: 'payouts-referral-bonus',
  },
  {
    title: 'Unilevel Bonus',
    icon: mdiCreditCard,
    to: 'payouts-unilevel-bonus',
  },
  {
    title: 'CPP bonus',
    icon: mdiCreditCard,
    to: 'payouts-cpp-bonus',
  },
  {
    title: 'Stockist Rebates',
    icon: mdiCreditCard,
    to: 'payouts-stockist-rebates',
  },
  {
    title: 'Withdrawals',
    icon: mdiWallet,
    to: 'withdrawal-requests',
  },
  {
    subheader: 'SALES',
  },
  {
    title: 'Sales Summary',
    icon: mdiCart,
    to: 'sales-summary',
  },
  {
    title: 'Sales Breakdown',
    icon: mdiCart,
    to: 'sales-breakdown',
  },
]
