<template>
  <v-card class="elevation-1">
    <v-card-title>
      Withdrawals
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          lg="2"
          md="2"
          class="mb-0 pb-0"
        >
          <v-autocomplete
              v-model="status"
              outlined
              dense
              label="Status"
              :items="['PENDING', 'PROCESSED']"
            ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
          class="mb-0 pb-0"
        >
          <!-- From Date -->
          <v-dialog
            ref="dialog"
            v-model="modalFromDate"
            :return-value.sync="fromDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                outlined
                dense
                placeholder="From Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
          class="mb-0 pb-0"
        >
          <!-- To Date -->
          <v-dialog
            ref="dialog"
            v-model="modalToDate"
            :return-value.sync="toDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="mb-0 pb-0 text-right"
        >
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="filter"
          >
            <v-icon
              small
            >
              {{ icon.filter }}
            </v-icon>
            Filter
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            :loading="exportLoading"
            @click="exportToExcel"
          >
            <v-icon
              small
            >
              {{ icon.excel }}
            </v-icon>
            Export
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="resetFilter"
          >
            <v-icon
              small
            >
              {{ icon.reset }}
            </v-icon>
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :single-select="false"
      dense
      :show-select="status==='PENDING'"
    >
      <template #[`item.amount`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.amount) }}</span>
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.processed_at === null ? 'error' : 'success'"
          label
          outlined
        >
          {{ item.processed_at === null ? 'PENDING' : 'PROCESSED' }}
        </v-chip>
      </template>
      <template #[`item.service_charge`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.service_charge) }}
      </template>
      <template #[`item.total_amount`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.total_amount) }}
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
    <confirm-password
      :show="showConfirmPassword"
      @cancel="showConfirmPassword=false"
      @execute="processed"
    >
    </confirm-password>

    <v-card-actions v-if="selectedItems.length > 0">
      <v-btn
        color="primary"
        class="mx-2 mt-4 mb-2"
        @click="confirmPassword"
      >
        <v-icon
          small
        >
          {{ icon.check }}
        </v-icon>
        Tag as processed
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'
import snackbar from '@/components/SnackBar.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import {
  mdiCalendarOutline,
  mdiFilter,
  mdiCancel,
  mdiFileExcel,
  mdiCheck,
} from '@mdi/js'

export default {
  components: {
    snackbar,
    ConfirmPassword,
  },
  setup() {
    const status = ref('PENDING')
    const selectedItems = ref([])
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const fromDate = ref('')
    const toDate = ref('')
    const modalFromDate = ref(false)
    const modalToDate = ref(false)
    const exportLoading = ref(false)
    const search = ref('')
    const headers = ref([
      { text: 'Member', align: 'start', value: 'name' },
      { text: 'Account ID', align: 'start', value: 'registration_code' },
      { text: 'Date/Time Requested', align: 'start', value: 'created_at' },
      { text: 'Amount Requested', align: 'end', value: 'amount' },
      { text: 'SC', align: 'end', value: 'service_charge' },
      { text: 'E-Savings', align: 'end', value: 'esavings' },
      { text: 'Other Deduction', align: 'end', value: 'cd_deduction' },
      { text: 'Total Amount', align: 'end', value: 'total_amount' },
      { text: 'Mode', align: 'start', value: 'mode' },
      { text: 'Remarks', align: 'start', value: 'remarks' },
      { text: 'Status', align: 'start', value: 'status' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)

    const { responseMessageStatus, responseMessage, updateNoFormFields } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/admin/withdrawals`

    const error = ref('')
    const reportData = ref(null)
    const filterValue = ref('')
    const showConfirmPassword = ref(false)

    watch(options, () => {
      if (filterValue.value !== '') {
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    })

    watch(searchTable, () => {
      if (filterValue.value !== '') {
        options.value.page = 1
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    })

    const filter = async () => {
      if (fromDate.value !== '' && toDate.value !== '') {
        filterValue.value = fnx.base64_encode({ fromDate: fromDate.value, toDate: toDate.value, status: status.value })
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      } else {
        responseMessage.value = 'Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const exportToExcel = async () => {
      if (fromDate.value !== '' && toDate.value !== '') {
        filterValue.value = fnx.base64_encode({ fromDate: fromDate.value, toDate: toDate.value, status: status.value })
        
        let mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

        await blob(`${process.env.VUE_APP_URI}/api/admin/withdrawals/${filterValue.value}/export`, reportData, exportLoading, error)
        if (error.value.data !== undefined) {
          responseMessage.value = error.value.data.message
          responseMessageStatus.value = error.value.status
        } else {
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }

      } else {
        responseMessage.value = 'Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const confirmPassword = () => {
      showConfirmPassword.value = true
    }

    const processed = () => {
      const uri = `${process.env.VUE_APP_URI}/api/admin/withdrawals/processed`
      updateNoFormFields(uri, loading, { items: selectedItems.value })
      getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      selectedItems.value = []
    }

    const resetFilter = () => {
      filterValue.value = ''
      toDate.value = ''
      fromDate.value = ''
      items.value = []
      totalItems.value = 0
    }

    return {
      filter,
      resetFilter,
      exportToExcel,

      icon: {
        calendar: mdiCalendarOutline,
        filter: mdiFilter,
        reset: mdiCancel,
        excel: mdiFileExcel,
        check: mdiCheck,
      },

      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      selectedItems,
      loading,
      exportLoading,
      
      status,
      fromDateValue,
      fromDate,
      modalFromDate,
      toDateValue,
      toDate,
      modalToDate,

      confirmPassword,
      showConfirmPassword,
      processed,

      responseMessageStatus,
      responseMessage,
      snackbar,

    }
  },
}
</script>
