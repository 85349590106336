<template>
  <v-card class="elevation-1" :loading="loading">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        title="Add New"
        v-else
      >
        <v-icon>{{icon.add}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12">
            <!-- Name -->
            <v-text-field
              v-model="stockist_name"
              label="Stockist Name"
              outlined
              dense
              :class="formErrorFields.stockist_nameErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.stockist_nameErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('stockist_name', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <v-text-field
              v-model="mobile_number"
              label="Mobile Number"
              v-mask="'09##-###-####'"
              outlined
              dense
              type="text"
              :class="formErrorFields.mobile_numberErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.mobile_numberErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('mobile_number', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <!-- Email -->
            <v-text-field
              v-model="email"
              label="Email"
              type="email"
              outlined
              dense
              :class="formErrorFields.emailErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.emailErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('email', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <v-autocomplete
              v-model="type"
              outlined
              dense
              clearable
              label="Type"
              item-text="title"
              item-value="id"
              :items="['BARANGAY STOCKIST','MUNICIPAL BUSINESS CENTER','PROVINCIAL DEPO']"
              :class="formErrorFields.typeErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.typeErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('type', formErrorFields)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <v-autocomplete
              v-model="province"
              outlined
              dense
              clearable
              autocomplete="off"
              label="Province"
              item-text="province_name"
              item-value="id"
              :loading="provinceLoading"
              :items="provinces"
              :class="formErrorFields.provinceErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.provinceErrorMessage"
              :disabled="formState===null"
              @change="provinceWasChange"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <v-autocomplete
              v-model="city"
              outlined
              dense
              clearable
              autocomplete="off"
              label="City / Municipal"
              item-text="municipality_name"
              item-value="id"
              :loading="cityLoading"
              :items="cities"
              :class="formErrorFields.cityErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.cityErrorMessage"
              :disabled="formState===null"
              @change="cityWasChange"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <v-autocomplete
              v-model="barangay"
              outlined
              dense
              clearable
              autocomplete="off"
              label="Barangay"
              item-text="barangay_name"
              item-value="id"
              :loading="barangayLoading"
              :items="barangays"
              :class="formErrorFields.barangayErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.barangayErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('barangay', formErrorFields)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <!-- Address -->
            <v-text-field
              v-model="address"
              autocomplete="off"
              label="Address"
              outlined
              dense
              :class="formErrorFields.addressErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.addressErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('address', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          color="success"
          :loading="loading"
          :disabled="formState===null"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          class="mx-2"
          color="default"
          :loading="loading"
          :disabled="formState===null"
          @click="formState=null">
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {mask} from 'vue-the-mask'
import { mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import get from '../../composables/get'

export default {
  directives: { mask },
  props: ['data'],
  setup(props, { emit }) {
    const loading = ref(false)
    const provinceLoading = ref(false)
    const cityLoading = ref(false)
    const barangayLoading = ref(false)
    const message = ref('')
    const provinces = ref([])
    const province = ref('')
    const cities = ref([])
    const city = ref('')
    const barangays = ref([])
    const barangay = ref('')
    const email = ref('')
    const type = ref('')
    const stockist_name = ref(null)
    const address = ref(null)
    const mobile_number = ref(null)
    const formErrorFields = ref({
      typeErrorMessage: '',
      emailErrorMessage: '',
      provinceErrorMessage: '',
      cityErrorMessage: '',
      barangayErrorMessage: '',
      stockist_nameErrorMessage: '',
      addressErrorMessage: '',
      mobile_numberErrorMessage: '',
    })
    const icon = {
      add: mdiPlusOutline,
    }
    const formState = ref(null)

    watch(() => props.data, async () => {
      await getProvince()
      await getCities(props.data.province_id)
      await getBarangay(props.data.municipality_id)

      email.value = props.data.email
      type.value = props.data.type
      province.value = props.data.province_id
      city.value = props.data.municipality_id
      barangay.value = props.data.barangay_id
      stockist_name.value = props.data.stockist_name
      address.value = props.data.address
      mobile_number.value = props.data.mobile_number
      formState.value = 'Edit'
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      province.value = ''
      type.value = ''
      city.value = ''
      barangay.value = ''
      email.value = ''
      stockist_name.value = null
      mobile_number.value = null
      address.value = null
      formState.value = null
    }

    const getProvince = async () => {
      await get(`${process.env.VUE_APP_URI}/api/area/provinces`, provinces, provinceLoading)
    }

    getProvince()

    const getCities = async provinceId => {
      await get(`${process.env.VUE_APP_URI}/api/area/cities/${provinceId}`, cities, cityLoading)
    }

    const getBarangay = async cityId => {
      await get(`${process.env.VUE_APP_URI}/api/area/barangays/${cityId}`, barangays, barangayLoading)
    }

    const provinceWasChange = () => {
      getCities(province.value)
      formError().remove('province', formErrorFields.value)
    }

    const cityWasChange = () => {
      getBarangay(city.value)
      formError().remove('city', formErrorFields.value)
    }

    const submit = async () => {
      const formData = {
        type: type.value,
        stockist_name: stockist_name.value,
        mobile_number: mobile_number.value,
        address: address.value,
        province: province.value,
        city: city.value,
        barangay: barangay.value,
        email: email.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/admin/stockists`
        await store(uri, loading, formData, formErrorFields)
      } else if (formState.value === 'Edit') {
        const uri = `${process.env.VUE_APP_URI}/api/admin/stockists/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
        reset()
      }
    }

    return {
      icon,
      provinces,
      province,
      cities,
      city,
      barangays,
      barangay,
      type,
      email,
      stockist_name,
      mobile_number,
      address,
      reset,
      submit,
      loading,
      provinceLoading,
      cityLoading,
      barangayLoading,
      message,
      formState,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      provinceWasChange,
      cityWasChange,
    }
  },

  components: {
    snackbar,
  },
}
</script>
