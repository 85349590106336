<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Update Member's Email
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="email"
            :label="`${memberName} New Email`"
            dense
            class="black--text mb-4"
            type="email"
            maxLength="50"
            :error-messages="formErrorFields.emailErrorMessage"
            @keydown="formError().remove('email', formErrorFields)"
            >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            text
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPackage } from '@mdi/js'
import formError from '@/composables/formErrorHandler/formError'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import get from '@/composables/get'

export default {
  components: {
    snackbar,
  },

  props: {
    showDialog: Boolean,
    data: null,
    errorMessage: [],
  },

  setup(props, { emit }) {
    const icon = { entry: mdiPackage }
    const dialog = ref(props.showDialog)
    const email = ref('')
    const loading = ref(false)
    const formErrorFields = ref({
      emailErrorMessage: '',
    })
    const selectedCodeQuantity = ref(0)
    const { store, responseMessageStatus, responseMessage } = crud()

    // get name
    const index = props.data.member_name.indexOf(") ")
    const memberName = props.data.member_name.substr(index + 1, props.data.member_name.lenght)

    watch(() => props.showDialog, val => {
      dialog.value = val
    })

    watch(() => props.errorMessage, val => {
      formErrorFields.value.emailErrorMessage = val
    })

    const selected = val => {
      formError().remove('email', formErrorFields.value)
    }

    const close = () => {
      dialog.value = false
      emit('closeDialog')
    }

    const submit = async () => {
      if(email.value === '') {
        formErrorFields.value.emailErrorMessage = "Email is required"
      } else {
        await store(`${process.env.VUE_APP_URI}/api/admin/members/update-email`, loading, { 
          id: props.data.id,
          email: email.value,
        }, formErrorFields)

        if (responseMessageStatus.value === 'success') {
          emit('updateTable')
          email.value = ''
          setTimeout(function() {
            emit('closeDialog')
          }, 2000)
        }
      }
    }

    return {
      email,
      dialog,
      icon,
      formErrorFields,
      formError,
      selected,
      selectedCodeQuantity,
      memberName,

      submit,
      close,

      responseMessage,
      responseMessageStatus,
      loading,
    }
  },
}
</script>
