<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="250px"
          max-width="200px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 left-panel-login"
        >
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pt-16 px-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Account Registration
                  </p>
                </v-card-text>

                <v-card-text>Personal Info</v-card-text>
                <v-card-text>
                  <v-col cols="12" class="py-0 my-0 px-0 px-0">
                    <v-text-field
                      v-model="formfields.firstname"
                      label="First Name"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.firstnameErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.firstnameErrorMessage"
                      @keydown="formError().remove('firstname', formErrorFields)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                      v-model="formfields.middlename"
                      label="Middle Name"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.middlenameErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.middlenameErrorMessage"
                      @keydown="formError().remove('middlename', formErrorFields)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                      v-model="formfields.lastname"
                      label="Last Name"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.lastnameErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.lastnameErrorMessage"
                      @keydown="formError().remove('lastname', formErrorFields)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                        v-model="formfields.email"
                        label="Email Address"
                        outlined
                        dense
                        type="emai"
                        :class="formErrorFields.emailErrorMessage.length > 0 ? 'mb-3' : ''"
                        :error-messages="formErrorFields.emailErrorMessage"
                        @keydown="formError().remove('email', formErrorFields)"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                      v-model="formfields.mobileNumber"
                      label="Mobile Number"
                      v-mask="'09##-###-####'"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.mobileNumberErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.mobileNumberErrorMessage"
                      @keydown="formError().remove('mobileNumber', formErrorFields)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                      v-model="formfields.address"
                      label="Address"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.addressErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.addressErrorMessage"
                      @keydown="formError().remove('address', formErrorFields)"
                    ></v-text-field>
                  </v-col>
                </v-card-text>

                <v-card-text class="my-0">Network Info</v-card-text>

                <v-card-text>
                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                      v-model="formfields.sponsor"
                      label="Sponsor Code"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.sponsorErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.sponsorErrorMessage"
                      @keydown="formError().remove('sponsor', formErrorFields)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                      v-model="formfields.activationCode"
                      label="Activation Code"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.activationCodeErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.activationCodeErrorMessage"
                      @keydown="formError().remove('activationCode', formErrorFields)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0 my-0 px-0">
                    <v-text-field
                      v-model="formfields.activationSerial"
                      label="Activation Serial"
                      outlined
                      dense
                      type="text"
                      :class="formErrorFields.activationSerialErrorMessage.length > 0 ? 'mb-3' : ''"
                      :error-messages="formErrorFields.activationSerialErrorMessage"
                      @keydown="formError().remove('activationSerial', formErrorFields)"
                    ></v-text-field>
                  </v-col>
                </v-card-text>

                <v-card-actions class="d-flex align-center justify-center flex-wrap mt-2">
                  <v-btn
                    color="success"
                    :loading="loading"
                    @click="submit">
                    Submit
                  </v-btn>
                </v-card-actions>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <span class="me-2">
                    Already have an account?
                  </span>
                  <router-link :to="{name:'auth-login-v2'}">
                    Sign in instead
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import {mask} from 'vue-the-mask'
import themeConfig from '@themeConfig'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '../../@core/utils'

export default {
  directives: { mask },
  components: { snackbar },

  setup() {
    const route = useRouter().route
    const loading = ref(false)
    const sponsorCode = ref('')
    const formfields = ref({
      firstname: '',
      middlename: '',
      lastname: '',
      email: '',
      mobileNumber: '',
      address: '',
      sponsor: '',
      activationCode: '',
      activationSerial: '',
    })
    const formErrorFields = ref({
      firstnameErrorMessage: '',
      middlenameErrorMessage: '',
      lastnameErrorMessage: '',
      emailErrorMessage: '',
      mobileNumberErrorMessage: '',
      addressErrorMessage: '',
      sponsorErrorMessage: '',
      activationCodeErrorMessage: '',
      activationSerialErrorMessage: '',
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formfields.value = {
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        mobileNumber: '',
        address: '',
        sponsor: '',
        activationCode: '',
        activationSerial: '',
      }
    }

    const getSponsorCode = async (id) => {
      await get(`${process.env.VUE_APP_URI}/api/member/network/sponsor/${id}/code`, sponsorCode, loading)
    }

    const identifySponsorCode = async () => {
      if (route.value.params.sponsor !== undefined) {
        await getSponsorCode(route.value.params.sponsor)
        formfields.value.sponsor = sponsorCode.value
      }
    }

    identifySponsorCode()

    const submit = async () => {
      const formData = formfields.value

      const uri = `${process.env.VUE_APP_URI}/api/auth/member/register`
      await store(uri, loading, formData, formErrorFields)
      if (responseMessageStatus.value === 'success') {
        reset()
      }
    }

    return {
      submit,
      loading,
      formfields,
      formError,
      formErrorFields,
      responseMessage,
      responseMessageStatus,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

<style scoped>
.left-panel-login {
  background-image: url("../../assets/images/others/network.jpg");
  background-size: cover;
  opacity: 0.6;
}
</style>
