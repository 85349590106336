<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <v-card
      class="auth-card pa-4 pt-7"
      max-width="448"
    >
      <v-card-text class="justify-center">
        <router-link
          to="/"
          class="d-flex justify-center"
        >
          <v-img
            :src="appLogo"
            max-height="250px"
            max-width="200px"
            alt="logo"
            contain
            class="me-3 "
          ></v-img>
        </router-link>

      </v-card-text>
      <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text class="text-center">
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Administrator
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account and start the adventure
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form>
                    <v-text-field
                      v-model="username"
                      outlined
                      label="Username"
                      placeholder="john@example.com"
                      class="mb-3"
                      :hide-details="formErrorFields.usernameErrorMessage.length === 0"
                      :error-messages="formErrorFields.usernameErrorMessage"
                      @keydown="formError().remove('username', formErrorFields)"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="············"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details
                      @click:append="isPasswordVisible = !isPasswordVisible"
                      @keypress.enter="signinWasClicked"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">

                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="mt-1"
                      >
                        Forgot Password?
                      </router-link>
                    </div>

                    <v-btn
                      block
                      class="mt-6"
                      color="success"
                      :loading="loading"
                      @click="signinWasClicked"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import signin from '@/composables/login/signin'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '@/@core/utils'

export default {
  components: {
    snackbar,
  },

  setup() {
    const loading = ref(false)
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const { router } = useRouter()
    const message = ref('')
    const formErrorFields = ref({
      usernameErrorMessage: '',
      passwordErrorMessage: '',
    })

    const { go, errorData } = signin()

    const signinWasClicked = async () => {
      loading.value = true
      await go(username, password, formErrorFields, router)
      if (errorData.value.status === 403) {
        await localStorage.setItem('userId', errorData.value.data)
        router.replace('/otp')
      } else {
        message.value = errorData.value.message !== undefined ? errorData.value.message : ''
        loading.value = false
      }
    }

    return {
      isPasswordVisible,
      username,
      password,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      signinWasClicked,
      formErrorFields,
      formError,
      message,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

<style scoped>
.auth-wrapper {
  background-image: url("../../assets/images/svg/background.svg");
  background-size: contain;
  /* opacity: 0.6; */
}

.auth-wrapper::before {
  content: "";
  opacity: .5 !important;
  transition: opacity 0.6s ease;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #f6fde8 !important;
}
</style>
