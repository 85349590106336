<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :uniqueId="uniqueId"
        ></user-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-overview></user-tab-overview>
          </v-tab-item>

          <v-tab-item>
            <user-tab-security
              @updateUserInfo="updateUserInfo"
            ></user-tab-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDatabase, mdiLockOutline } from '@mdi/js'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'

export default {
  components: {
    UserTabOverview,
    UserTabSecurity,
    UserBioPanel,
  },
  setup() {

    const userTab = ref(null)
    const uniqueId = ref('')

    const tabs = [
      { icon: mdiDatabase, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Security' },
    ]

    const updateUserInfo = () => {
      uniqueId.value = Math.random(10)
    }

    return {
      tabs,
      userTab,
      updateUserInfo,
      uniqueId,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
