<template>
  <v-card class="elevation-1">
    <v-card-title>
      Unilevel Bonus
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          lg="4"
          md="4"
          class="mb-0 pb-0"
        >
          <v-select
            v-model="cutoff"
            :items="cutoffs"
            :item-text="'cutoff'"
            :item-key="'cutoff'"
            label="Cutoff"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="mb-0 pb-0 text-right"
        >
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="filter"
          >
            <v-icon
              small
            >
              {{ icon.filter }}
            </v-icon>
            Filter
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            :loading="exportLoading"
            @click="exportToExcel"
          >
            <v-icon
              small
            >
              {{ icon.excel }}
            </v-icon>
            Export
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="resetFilter"
          >
            <v-icon
              small
            >
              {{ icon.reset }}
            </v-icon>
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.gross`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.gross) }}</span>
      </template>
      <template #[`item.wtax`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.wtax) }}</span>
      </template>
      <template #[`item.net`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.net) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import get from '@/composables/get'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import {
  mdiCalendarOutline,
  mdiFilter,
  mdiCancel,
  mdiFileExcel,
} from '@mdi/js'
import crud from '@/composables/crud'
import fnx from '@/functions/fn'
import blob from '@/composables/blob'

export default {
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Member ID', align: 'start', value: 'registration_code' },
      { text: 'Member Name', align: 'start', value: 'name' },
      { text: 'Gross', align: 'end', value: 'gross' },
      { text: 'WTax', align: 'end', value: 'wtax' },
      { text: 'Net', align: 'end', value: 'net' },
    ])
    const cutoff = ref('')
    const cutoffs = ref([])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const error = ref('')
    const reportData = ref(null)

    const filterValue = ref('')

    const getCutoff = async () => {
      await get(`${process.env.VUE_APP_URI}/api/admin/payouts/unilevel-cutoff`, cutoffs, null)
      // cutoff.value = cutoffs.value[0]
    }

    getCutoff()

    const listUri = `${process.env.VUE_APP_URI}/api/admin/payouts/unilevel-bonus`
    const { responseMessageStatus, responseMessage } = crud()

    watch(options, () => {
      if (filterValue.value !== '') {
        getData(listUri, items, totalItems, loading, options, search)
      }
    })

    watch(searchTable, () => {
      if (filterValue.value !== '') {
        options.value.page = 1
        getData(listUri, items, totalItems, loading, options, search)
      }
    })

    const filter = async () => {
      if (cutoff.value !== '') {
        filterValue.value = fnx.base64_encode({ cutoff: cutoff.value })
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      } else {
        responseMessage.value = 'Cutoff are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const exportToExcel = async () => {
      if (cutoff.value !== '') {
        filterValue.value = fnx.base64_encode({ cutoff: cutoff.value })
        
        let mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

        await blob(`${process.env.VUE_APP_URI}/api/admin/payouts/unilevel-bonus/${filterValue.value}/export`, reportData, exportLoading, error)
        if (error.value.data !== undefined) {
          responseMessage.value = error.value.data.message
          responseMessageStatus.value = error.value.status
        } else {
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }

      } else {
        responseMessage.value = 'Cutoff are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const resetFilter = () => {
      filterValue.value = ''
      cutoff.value = ''
      items.value = []
      totalItems.value = 0
    }

    return {
      filter,
      resetFilter,
      exportToExcel,

      icon: {
        calendar: mdiCalendarOutline,
        filter: mdiFilter,
        reset: mdiCancel,
        excel: mdiFileExcel,
      },

      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      exportLoading,
      cutoff,
      cutoffs,
    }
  },

  components: {
    snackbar,
  },
}
</script>
