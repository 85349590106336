<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
    >
      <form-view
        :data="fomrData"
        @updateUniqueID="updateUniqueID"
      ></form-view>
    </v-col>
    <v-col
      cols="12"
      lg="8"
      md="8"
      xl="8"
    >
      <list-view
        @passData="passData"
        :uniqueID="uniqueId"
        @updateStatus="updateStatus"
      ></list-view>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from '@/views/Stockists/form.vue'
import ListView from '@/views/Stockists/list.vue'

export default {
  components: {
    ListView,
    FormView,
  },

  setup() {
    const fomrData = ref({})
    const uniqueId = ref('')
    const status = ref('')
    const selectedCodes = ref([])

    const passData = d => {
      fomrData.value = d
    }

    const updateUniqueID = () => {
      uniqueId.value = Math.random()
    }

    const updateStatus = (val, data) => {
      status.value = val
      selectedCodes.value = data
    }

    return {
      fomrData,
      passData,
      updateUniqueID,
      uniqueId,
      status,
      updateStatus,
      selectedCodes,
    }
  },
}
</script>
