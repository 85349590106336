<template>
  <v-card class="elevation-1">
    <v-card-title>
      Sales Breakdown
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          lg="4"
          md="4"
          class="mb-0 pb-0"
        >
          <!-- From Date -->
          <v-dialog
            ref="dialog"
            v-model="modalFromDate"
            :return-value.sync="fromDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                outlined
                dense
                placeholder="From Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="4"
          class="mb-0 pb-0"
        >
          <!-- To Date -->
          <v-dialog
            ref="dialog"
            v-model="modalToDate"
            :return-value.sync="toDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="mb-0 pb-0 text-right"
        >
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="filter"
          >
            <v-icon
              small
            >
              {{ icon.filter }}
            </v-icon>
            Filter
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            :loading="exportLoading"
            @click="exportToExcel"
          >
            <v-icon
              small
            >
              {{ icon.excel }}
            </v-icon>
            Export
          </v-btn>
          <v-btn
            color="primary"
            class="mb-2 mx-1"
            @click="resetFilter"
          >
            <v-icon
              small
            >
              {{ icon.reset }}
            </v-icon>
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.gross`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.gross) }}</span>
      </template>
      <template #[`item.wtax`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.wtax) }}</span>
      </template>
      <template #[`item.net`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.net) }}</span>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import {
  mdiCalendarOutline,
  mdiFilter,
  mdiCancel,
  mdiFileExcel,
} from '@mdi/js'
import crud from '@/composables/crud'
import fnx from '@/functions/fn'
import blob from '@/composables/blob'

export default {
  setup(props) {
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const fromDate = ref('')
    const toDate = ref('')
    const modalFromDate = ref(false)
    const modalToDate = ref(false)
    const search = ref('')
    const headers = ref([
      { text: 'Date Sold', align: 'start', value: 'transaction_date' },
      { text: 'Time Sold', align: 'start', value: 'transaction_time' },
      { text: 'Reference Number', align: 'start', value: 'reference_number' },
      { text: 'Product', align: 'start', value: 'product_name' },
      { text: 'Quantity', align: 'start', value: 'quantity' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const error = ref('')
    const reportData = ref(null)

    const filterValue = ref('')

    const { responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/admin/sales/breakdown`

    watch(options, () => {
      if (filterValue.value !== '') {
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    })

    watch(searchTable, () => {
      if (filterValue.value !== '') {
        options.value.page = 1
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      }
    })

    const filter = async () => {
      if (fromDate.value !== '' && toDate.value !== '') {
        filterValue.value = fnx.base64_encode({ fromDate: fromDate.value, toDate: toDate.value })
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      } else {
        responseMessage.value = 'Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const exportToExcel = async () => {
      if (fromDate.value !== '' && toDate.value !== '') {
        filterValue.value = fnx.base64_encode({ fromDate: fromDate.value, toDate: toDate.value })
        
        let mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

        await blob(`${process.env.VUE_APP_URI}/api/admin/sales/breakdown/${filterValue.value}/export`, reportData, exportLoading, error)
        if (error.value.data !== undefined) {
          responseMessage.value = error.value.data.message
          responseMessageStatus.value = error.value.status
        } else {
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }

      } else {
        responseMessage.value = 'Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const resetFilter = () => {
      filterValue.value = ''
      toDate.value = ''
      fromDate.value = ''
      items.value = []
      totalItems.value = 0
    }

    return {
      filter,
      resetFilter,
      exportToExcel,

      icon: {
        calendar: mdiCalendarOutline,
        filter: mdiFilter,
        reset: mdiCancel,
        excel: mdiFileExcel,
      },
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      exportLoading,
      fromDateValue,
      fromDate,
      modalFromDate,
      toDateValue,
      toDate,
      modalToDate,

      responseMessageStatus,
      responseMessage,
    }
  },

  components: {
    snackbar,
  },
}
</script>
