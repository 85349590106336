<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Tag as paid
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="referenceNo"
            label="Reference Number"
            dense
            class="black--text mb-4"
            type="text"
            maxLength="20"
          />
          <v-text-field
            v-model="member_code"
            dense
            label="Member Code"
            :class="formErrorFields.member_codeErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.member_codeErrorMessage"
            @change="selected"
            maxLength="10"
            readonly
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            text
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPackage } from '@mdi/js'
import formError from '@/composables/formErrorHandler/formError'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import get from '@/composables/get'

export default {
  components: {
    snackbar,
  },

  props: {
    show: Boolean,
    data: null,
    errorMessage: [],
  },

  setup(props, { emit }) {
    const icon = { entry: mdiPackage }
    const dialog = ref(props.show)
    const referenceNo = ref('')
    const codes = ref([])
    const loading = ref(false)
    const member_code = ref(props.data.registration_code)
    const formErrorFields = ref({
      member_codeErrorMessage: '',
    })
    const { store, responseMessageStatus, responseMessage } = crud()

    watch(() => props.show, val => {
      console.log(val)
      dialog.value = val
    })

    watch(() => props.errorMessage, val => {
      formErrorFields.value.member_codeErrorMessage = val
    })

    const selected = val => {
      formError().remove('member_code', formErrorFields.value)
    }

    const close = () => {
      dialog.value = false
      emit('closeDialog')
    }

    const submit = async () => {
      if(member_code.value === '') {
        formErrorFields.value.member_codeErrorMessage = "Member Code is required"
      } else {
        await store(`${process.env.VUE_APP_URI}/api/admin/members/tag-as-paid`, loading, { 
          memberId: props.data.id,
          memberCode: member_code.value,
          transactionNumber: referenceNo.value,
        }, formErrorFields)

        if (responseMessageStatus.value === 'success') {
          emit('updateTable')
          setTimeout(function() {
            emit('closeDialog')
          }, 2000)
        }
      }
    }

    return {
      referenceNo,
      codes,
      dialog,
      icon,
      member_code,
      formErrorFields,
      formError,
      selected,

      submit,
      close,

      responseMessage,
      responseMessageStatus,
      loading,
    }
  },
}
</script>
