import axios from 'axios'
import Cookies from 'universal-cookie'

const requests = () => {
  const cookie = new Cookies()
  const accessToken = cookie.get('access_token')

  const protectRequest = error => {
    console.log(error)
    if (error.response.status === 401) {
      cookie.remove('access_token', {
        path: '/',
        sameSite: true,
      })

      cookie.remove('user', {
        path: '/',
        sameSite: true,
      })

      cookie.remove('expires_in', {
        path: '/',
        sameSite: true,
      })

      window.location.replace = '/'
    }
  }

  axios.defaults.withCredentials = true
  axios.interceptors.request.use(conf => {
    // Do something before request is sent
    const config = conf
    config.headers.Authorization = `Bearer ${accessToken}`
    config.headers.accept = 'Application/json'

    return config
  }, error => Promise.reject(error))

  axios.interceptors.response.use(response => response, error => {
    protectRequest(error)

    return Promise.reject(error)
  })
}

export default requests
